<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6  col-md-6">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <div class="btn-toolbar p-3" role="toolbar">
                Information Contravention
<!--                <button type="button" @click="deleteThisIncident()" class="btn btn-danger me-2">
                  <i class="mdi mdi-trash-can"></i>
                  Désactiver
                </button>-->
              </div>
            </div>
          </div>
          <div class="card-body" >


            <div class="row mt-3 justify-content-between">
              <div class="col-md-4">
                <strong class="font-size-13 text-primary">Type:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13"> {{ contavention?.Typevehicule }} </strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Immatriculation :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.immatriculation }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Date de creation:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{ contavention?.created_at?.split('T')[0] }} à {{ contavention?.created_at?.split('T')[1]?.split('.')[0] }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Lieu  Infraction:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.LieuInfraction }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4">
                <strong class="font-size-13 text-primary">type de contravention:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13"> {{ TypeContravention?.nomType }} </strong></h5>
              </div>
            </div>



            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Montant:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  TypeContravention?.montant }}</strong></h5>
              </div>
            </div>



            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Agent:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.agent }}</strong></h5>
              </div>
            </div>

<!--<div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">numero Infraction:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.numeroInfraction }}</strong></h5>
              </div>
            </div>-->
<!--<div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">description Contravention:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.descriptionContravention }}</strong></h5>
              </div>
            </div>-->


<!--            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Statut Contravention:</strong>
              </div>
              <div class="col-md-8">
                <h5>
                  <span v-if="contavention?.StatutContravention === 'paye'" class="badge badge-pill badge-soft-success font-size-11">
                                                 Paiement effectué
                                            </span>

                  <span v-if="contavention?.StatutContravention === 'non-paye'" class="badge badge-pill badge-soft-warning font-size-11">
                                                 Paiement non effectué
                                            </span>
                </h5>
              </div>
            </div>-->

<!--
            <div v-if="contavention?.StatutContravention === 'paye'" class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Montant payé:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.montantpaye }}</strong></h5>
              </div>
            </div>

            <div v-if="contavention?.StatutContravention === 'paye'" class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">date de paiement:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.date_paiement }}</strong></h5>
              </div>
            </div>
-->




          </div>
        </div>

      </div>
<!--      <div class="col-lg-6  col-md-6">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <div class="btn-toolbar p-3" role="toolbar">
                Information Vehicule
&lt;!&ndash;                <button type="button" @click="deleteThisIncident()" class="btn btn-danger me-2">
                  <i class="mdi mdi-trash-can"></i>
                  Désactiver
                </button>&ndash;&gt;
              </div>
            </div>
          </div>
          <div class="card-body" >

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4">
                <strong class="font-size-13 text-primary">Marque:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13"> {{ contavention?.marque }} </strong></h5>
              </div>
            </div>
            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">Modele:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.model }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">numero chassis:</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.numerochassis }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">annee :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.annee }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">couleur :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.couleur }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">nom contrevenant :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.nom_contrevenant }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">prenom contrevenant :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.prenom_contrevenant }}</strong></h5>
              </div>
            </div>

            <div class="row mt-3 justify-content-between">
              <div class="col-md-4 text-primary">
                <strong class="font-size-13">cni contrevenant :</strong>
              </div>
              <div class="col-md-8">
                <h5><strong class="font-size-13">{{  contavention?.cni_contrevenant }}</strong></h5>
              </div>
            </div>







          </div>
        </div>

      </div>-->

      <div class="col-lg-6  col-md-6">
        <div class="card">
          <div class="row justify-content-between">
            <div class="col-md-6">
              <div class="btn-toolbar p-3" role="toolbar">
                Statut contravention

              </div>
            </div>
          </div>
          <div class="card-body">

            <table class="table table-nowrap table-centered mb-0 align-middle">
              <thead class="table-light">
              <tr>
                <th>Type amande</th>
                <th>Montant amande </th>
                <th scope="col">Date limite</th>
                <th scope="col">action </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Amande Forfaitaire </td>
                <td>{{ topcontavention?.final_montant}}</td>
                <td>{{ topcontavention?.final_date}}</td>
                <td>Payé</td>
<!--                <td>
                  <button  v-if="contavention?.StatutContravention === 'non-paye' && topcontavention?.typeAmande === 1" type="button" @click="showModal = true" class="btn btn-primary">
                    payer
                  </button>
                </td>-->
              </tr>
              <tr>
                <td>Amande Majorée1 </td>
                <td>{{ topcontavention?.final_montant2}}</td>
                <td>{{ topcontavention?.final_date2}}</td>
                <td>Non Payé</td>
<!--                <td>
                  <button  v-if="contavention?.StatutContravention === 'non-paye' && topcontavention?.typeAmande === 2" type="button" @click="showModal = true" class="btn btn-primary">
                    payer
                  </button>
                </td>-->
              </tr>
              <tr>
                <td>Amande Majorée2 </td>
                <td>{{ topcontavention?.final_montant3}}</td>
                <td>{{ topcontavention?.final_date3}}</td>
                <td>Non Payé</td>
<!--                <td>

                  <button  v-if="contavention?.StatutContravention === 'non-paye' && topcontavention?.typeAmande === 3" type="button" @click="showModal = true" class="btn btn-primary">
                  payer
                </button>

                </td>-->
              </tr>
              </tbody>
            </table>

            </div>
        </div>
      </div>

    </div>

    <b-modal v-model="showModal" title="Validation du paiement" hide-footer>
      <p class="my-4">Montant a payé: <strong>{{topcontavention?.montantapayer}}</strong></p>

      <div class="row justify-content-end">
        <div class="col-md-10">
          <button type="button" @click="showModal = false" class="btn btn-primary">
            Annuler
          </button>
          <button @click="validepayement()" type="button" class="btn btn-success" style="float: right">
            Valider
          </button>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {reqMethods} from "../../../state/helpers";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      contavention: {},
      TypeContravention: {},
      topcontavention: null,
      fetchError: null,
      title: "Details contravention",
      reqError: null,
      reqResponse: null,
      showModal: false,
      items: [
        {
          text: "Contravention",
          href: "/"
        },
        {
          text: "Détails",
          active: true
        }
      ],
    }
  },
  methods: {
    ...reqMethods,
    async validepayement() {
      this.showModal = false;

      const formData = new FormData();
      let fid = parseInt(this.$route.params.id);
      formData.append('montant', this.topcontavention?.montantapayer);
      formData.append('id', fid);
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/contravention/contraventionPaiement/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "Paiement effectué!", "success");

      } catch (error) {

        Swal.fire({
          title: "Oops...",
          text: "Erreur lors traitement du paiement",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }

    },
    getDetailsContraventions() {
      const that = this;
      const id = { id: parseInt(this.$route.params.id) };
      this.postRequest({
        route: '/contravention/findbyid/',
        data: id,
      }).then(
          function (response) {
            that.contavention = response.data?.contraventions[0];
            that.TypeContravention = response.data?.TypeContravention[0];
            that.topcontavention = response.data;
          },
          function (error) {
            that.reqError = error;
          }
      )
    },
  },
  mounted() {
    this.getDetailsContraventions();
  }
}
</script>

<style scoped>

</style>